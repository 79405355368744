
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SliderMulti extends Vue {
  @Prop(Array) ticks!: string[];
  @Prop(Number) value!: number;
  @Prop({ type: String, default: "250px" }) width!: string;

  selected = this.value;

  setValue(index: number) {
    this.selected = index;
    this.$emit("input", index);
  }

  posPercent(index: number): string {
    return (index / (this.ticks.length - 1)) * 100 + "%";
  }

  handleMouseOver(index: number, event: MouseEvent) {
    if (event.buttons === 1 && this.$refs.slider === document.activeElement) {
      this.setValue(index);
    }
  }

  handleKey(event: KeyboardEvent) {
    if (event.key === "ArrowRight" && this.selected < this.ticks.length - 1) {
      this.selected++;
    } else if (event.key === "ArrowLeft" && this.selected > 0) {
      this.selected--;
    }
  }
}
