











import { Component, Vue } from "vue-property-decorator";

import SliderIconStyles from "@/components/menu/SliderIconStyles.vue";
import { Game } from "@/store/settings";

@Component({
  components: {
    SliderIconStyles,
  },
})
export default class IconStyles extends Vue {
  categories = this.$store.state.settings.iconStyles;

  get game(): Game {
    return this.$store.state.settings.game;
  }
}
