




import { Component, Prop, Vue } from "vue-property-decorator";

import { IconStyle } from "@/store/settings";
import SliderMulti from "./SliderMulti.vue";

@Component({
  components: {
    SliderMulti,
  },
})
export default class SliderIconStyles extends Vue {
  @Prop(String) name!: string;
  @Prop(Array) options!: string[];
  @Prop(String) iconStyle!: IconStyle;

  get value(): number {
    const index = this.options.indexOf(this.iconStyle);
    if (index === -1) {
      this.$store.commit("settings/setIconStyle", { name: this.name, value: "Default" });
      return this.value;
    }

    return index;
  }

  set value(newValue) {
    this.$store.commit("settings/setIconStyle", { name: this.name, value: this.options[newValue] });
  }
}
